exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manage-achievement-tsx": () => import("./../../../src/pages/manage/achievement.tsx" /* webpackChunkName: "component---src-pages-manage-achievement-tsx" */),
  "component---src-pages-manage-activities-tsx": () => import("./../../../src/pages/manage/activities.tsx" /* webpackChunkName: "component---src-pages-manage-activities-tsx" */),
  "component---src-pages-manage-avatar-frame-tsx": () => import("./../../../src/pages/manage/avatar-frame.tsx" /* webpackChunkName: "component---src-pages-manage-avatar-frame-tsx" */),
  "component---src-pages-manage-banks-tsx": () => import("./../../../src/pages/manage/banks.tsx" /* webpackChunkName: "component---src-pages-manage-banks-tsx" */),
  "component---src-pages-manage-bots-tsx": () => import("./../../../src/pages/manage/bots.tsx" /* webpackChunkName: "component---src-pages-manage-bots-tsx" */),
  "component---src-pages-manage-classes-time-learning-tsx": () => import("./../../../src/pages/manage/classes-time-learning.tsx" /* webpackChunkName: "component---src-pages-manage-classes-time-learning-tsx" */),
  "component---src-pages-manage-classes-tsx": () => import("./../../../src/pages/manage/classes.tsx" /* webpackChunkName: "component---src-pages-manage-classes-tsx" */),
  "component---src-pages-manage-config-land-item-tsx": () => import("./../../../src/pages/manage/config-land-item.tsx" /* webpackChunkName: "component---src-pages-manage-config-land-item-tsx" */),
  "component---src-pages-manage-configs-tsx": () => import("./../../../src/pages/manage/configs.tsx" /* webpackChunkName: "component---src-pages-manage-configs-tsx" */),
  "component---src-pages-manage-dictionary-tsx": () => import("./../../../src/pages/manage/dictionary.tsx" /* webpackChunkName: "component---src-pages-manage-dictionary-tsx" */),
  "component---src-pages-manage-events-tsx": () => import("./../../../src/pages/manage/events.tsx" /* webpackChunkName: "component---src-pages-manage-events-tsx" */),
  "component---src-pages-manage-games-tsx": () => import("./../../../src/pages/manage/games.tsx" /* webpackChunkName: "component---src-pages-manage-games-tsx" */),
  "component---src-pages-manage-index-tsx": () => import("./../../../src/pages/manage/index.tsx" /* webpackChunkName: "component---src-pages-manage-index-tsx" */),
  "component---src-pages-manage-items-tsx": () => import("./../../../src/pages/manage/items.tsx" /* webpackChunkName: "component---src-pages-manage-items-tsx" */),
  "component---src-pages-manage-learns-tsx": () => import("./../../../src/pages/manage/learns.tsx" /* webpackChunkName: "component---src-pages-manage-learns-tsx" */),
  "component---src-pages-manage-level-mapping-tsx": () => import("./../../../src/pages/manage/level-mapping.tsx" /* webpackChunkName: "component---src-pages-manage-level-mapping-tsx" */),
  "component---src-pages-manage-locales-props-tsx": () => import("./../../../src/pages/manage/locales-props.tsx" /* webpackChunkName: "component---src-pages-manage-locales-props-tsx" */),
  "component---src-pages-manage-locales-tsx": () => import("./../../../src/pages/manage/locales.tsx" /* webpackChunkName: "component---src-pages-manage-locales-tsx" */),
  "component---src-pages-manage-mailbox-tsx": () => import("./../../../src/pages/manage/mailbox.tsx" /* webpackChunkName: "component---src-pages-manage-mailbox-tsx" */),
  "component---src-pages-manage-mailtemplates-tsx": () => import("./../../../src/pages/manage/mailtemplates.tsx" /* webpackChunkName: "component---src-pages-manage-mailtemplates-tsx" */),
  "component---src-pages-manage-maintenance-tsx": () => import("./../../../src/pages/manage/maintenance.tsx" /* webpackChunkName: "component---src-pages-manage-maintenance-tsx" */),
  "component---src-pages-manage-maps-tsx": () => import("./../../../src/pages/manage/maps.tsx" /* webpackChunkName: "component---src-pages-manage-maps-tsx" */),
  "component---src-pages-manage-npc-tsx": () => import("./../../../src/pages/manage/npc.tsx" /* webpackChunkName: "component---src-pages-manage-npc-tsx" */),
  "component---src-pages-manage-popup-notification-tsx": () => import("./../../../src/pages/manage/popup-notification.tsx" /* webpackChunkName: "component---src-pages-manage-popup-notification-tsx" */),
  "component---src-pages-manage-quests-tsx": () => import("./../../../src/pages/manage/quests.tsx" /* webpackChunkName: "component---src-pages-manage-quests-tsx" */),
  "component---src-pages-manage-roles-tsx": () => import("./../../../src/pages/manage/roles.tsx" /* webpackChunkName: "component---src-pages-manage-roles-tsx" */),
  "component---src-pages-manage-subcriptions-tsx": () => import("./../../../src/pages/manage/subcriptions.tsx" /* webpackChunkName: "component---src-pages-manage-subcriptions-tsx" */),
  "component---src-pages-manage-user-activities-tsx": () => import("./../../../src/pages/manage/user-activities.tsx" /* webpackChunkName: "component---src-pages-manage-user-activities-tsx" */),
  "component---src-pages-manage-user-profile-tsx": () => import("./../../../src/pages/manage/user-profile.tsx" /* webpackChunkName: "component---src-pages-manage-user-profile-tsx" */),
  "component---src-pages-manage-user-subcription-source-tsx": () => import("./../../../src/pages/manage/user-subcription-source.tsx" /* webpackChunkName: "component---src-pages-manage-user-subcription-source-tsx" */),
  "component---src-pages-manage-users-tsx": () => import("./../../../src/pages/manage/users.tsx" /* webpackChunkName: "component---src-pages-manage-users-tsx" */),
  "component---src-pages-manage-vouchers-tsx": () => import("./../../../src/pages/manage/vouchers.tsx" /* webpackChunkName: "component---src-pages-manage-vouchers-tsx" */),
  "component---src-pages-parent-tsx": () => import("./../../../src/pages/parent.tsx" /* webpackChunkName: "component---src-pages-parent-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-report-active-tsx": () => import("./../../../src/pages/report/active.tsx" /* webpackChunkName: "component---src-pages-report-active-tsx" */),
  "component---src-pages-report-active-with-blocks-tsx": () => import("./../../../src/pages/report/active-with-blocks.tsx" /* webpackChunkName: "component---src-pages-report-active-with-blocks-tsx" */),
  "component---src-pages-report-arena-tsx": () => import("./../../../src/pages/report/arena.tsx" /* webpackChunkName: "component---src-pages-report-arena-tsx" */),
  "component---src-pages-report-brainwar-tsx": () => import("./../../../src/pages/report/brainwar.tsx" /* webpackChunkName: "component---src-pages-report-brainwar-tsx" */),
  "component---src-pages-report-ccu-tsx": () => import("./../../../src/pages/report/ccu.tsx" /* webpackChunkName: "component---src-pages-report-ccu-tsx" */),
  "component---src-pages-report-churnrate-tsx": () => import("./../../../src/pages/report/churnrate.tsx" /* webpackChunkName: "component---src-pages-report-churnrate-tsx" */),
  "component---src-pages-report-first-session-tsx": () => import("./../../../src/pages/report/first-session.tsx" /* webpackChunkName: "component---src-pages-report-first-session-tsx" */),
  "component---src-pages-report-load-time-tsx": () => import("./../../../src/pages/report/load-time.tsx" /* webpackChunkName: "component---src-pages-report-load-time-tsx" */),
  "component---src-pages-report-monetization-tsx": () => import("./../../../src/pages/report/monetization.tsx" /* webpackChunkName: "component---src-pages-report-monetization-tsx" */),
  "component---src-pages-report-new-user-tsx": () => import("./../../../src/pages/report/new-user.tsx" /* webpackChunkName: "component---src-pages-report-new-user-tsx" */),
  "component---src-pages-report-new-users-weekly-tsx": () => import("./../../../src/pages/report/new-users-weekly.tsx" /* webpackChunkName: "component---src-pages-report-new-users-weekly-tsx" */),
  "component---src-pages-report-player-tsx": () => import("./../../../src/pages/report/player.tsx" /* webpackChunkName: "component---src-pages-report-player-tsx" */),
  "component---src-pages-report-realtime-table-tsx": () => import("./../../../src/pages/report/realtime-table.tsx" /* webpackChunkName: "component---src-pages-report-realtime-table-tsx" */),
  "component---src-pages-report-realtime-tsx": () => import("./../../../src/pages/report/realtime.tsx" /* webpackChunkName: "component---src-pages-report-realtime-tsx" */),
  "component---src-pages-report-report-learning-tsx": () => import("./../../../src/pages/report/report-learning.tsx" /* webpackChunkName: "component---src-pages-report-report-learning-tsx" */),
  "component---src-pages-report-retention-tsx": () => import("./../../../src/pages/report/retention.tsx" /* webpackChunkName: "component---src-pages-report-retention-tsx" */),
  "component---src-pages-report-sessions-tsx": () => import("./../../../src/pages/report/sessions.tsx" /* webpackChunkName: "component---src-pages-report-sessions-tsx" */),
  "component---src-pages-report-sessions-weekly-tsx": () => import("./../../../src/pages/report/sessions-weekly.tsx" /* webpackChunkName: "component---src-pages-report-sessions-weekly-tsx" */),
  "component---src-pages-report-sessions-with-blocks-tsx": () => import("./../../../src/pages/report/sessions-with-blocks.tsx" /* webpackChunkName: "component---src-pages-report-sessions-with-blocks-tsx" */),
  "component---src-pages-report-stickiness-tsx": () => import("./../../../src/pages/report/stickiness.tsx" /* webpackChunkName: "component---src-pages-report-stickiness-tsx" */),
  "component---src-pages-report-subcription-sale-group-source-tsx": () => import("./../../../src/pages/report/subcription-sale-group-source.tsx" /* webpackChunkName: "component---src-pages-report-subcription-sale-group-source-tsx" */),
  "component---src-pages-report-subcription-sale-tsx": () => import("./../../../src/pages/report/subcription-sale.tsx" /* webpackChunkName: "component---src-pages-report-subcription-sale-tsx" */),
  "component---src-pages-report-summary-tsx": () => import("./../../../src/pages/report/summary.tsx" /* webpackChunkName: "component---src-pages-report-summary-tsx" */),
  "component---src-pages-report-total-tsx": () => import("./../../../src/pages/report/total.tsx" /* webpackChunkName: "component---src-pages-report-total-tsx" */),
  "component---src-pages-report-total-user-with-blocks-tsx": () => import("./../../../src/pages/report/total-user-with-blocks.tsx" /* webpackChunkName: "component---src-pages-report-total-user-with-blocks-tsx" */),
  "component---src-pages-report-user-bought-items-tsx": () => import("./../../../src/pages/report/user-bought-items.tsx" /* webpackChunkName: "component---src-pages-report-user-bought-items-tsx" */),
  "component---src-pages-report-user-checkin-daily-tsx": () => import("./../../../src/pages/report/user-checkin-daily.tsx" /* webpackChunkName: "component---src-pages-report-user-checkin-daily-tsx" */),
  "component---src-pages-report-user-count-tsx": () => import("./../../../src/pages/report/user-count.tsx" /* webpackChunkName: "component---src-pages-report-user-count-tsx" */),
  "component---src-pages-report-user-delete-tsx": () => import("./../../../src/pages/report/user-delete.tsx" /* webpackChunkName: "component---src-pages-report-user-delete-tsx" */),
  "component---src-pages-report-user-exp-tsx": () => import("./../../../src/pages/report/user-exp.tsx" /* webpackChunkName: "component---src-pages-report-user-exp-tsx" */),
  "component---src-pages-report-user-gold-tsx": () => import("./../../../src/pages/report/user-gold.tsx" /* webpackChunkName: "component---src-pages-report-user-gold-tsx" */),
  "component---src-pages-report-user-play-game-daily-tsx": () => import("./../../../src/pages/report/user-play-game-daily.tsx" /* webpackChunkName: "component---src-pages-report-user-play-game-daily-tsx" */),
  "component---src-pages-report-user-play-game-tsx": () => import("./../../../src/pages/report/user-play-game.tsx" /* webpackChunkName: "component---src-pages-report-user-play-game-tsx" */),
  "component---src-pages-report-user-use-items-tsx": () => import("./../../../src/pages/report/user-use-items.tsx" /* webpackChunkName: "component---src-pages-report-user-use-items-tsx" */),
  "component---src-pages-sales-callback-cancel-tsx": () => import("./../../../src/pages/sales/callback/cancel.tsx" /* webpackChunkName: "component---src-pages-sales-callback-cancel-tsx" */),
  "component---src-pages-sales-callback-success-tsx": () => import("./../../../src/pages/sales/callback/success.tsx" /* webpackChunkName: "component---src-pages-sales-callback-success-tsx" */),
  "component---src-pages-sales-codes-tsx": () => import("./../../../src/pages/sales/codes.tsx" /* webpackChunkName: "component---src-pages-sales-codes-tsx" */),
  "component---src-pages-sales-orders-tsx": () => import("./../../../src/pages/sales/orders.tsx" /* webpackChunkName: "component---src-pages-sales-orders-tsx" */),
  "component---src-pages-sales-packages-tsx": () => import("./../../../src/pages/sales/packages.tsx" /* webpackChunkName: "component---src-pages-sales-packages-tsx" */),
  "component---src-pages-sales-sellers-tsx": () => import("./../../../src/pages/sales/sellers.tsx" /* webpackChunkName: "component---src-pages-sales-sellers-tsx" */),
  "component---src-pages-sales-teams-[id]-tsx": () => import("./../../../src/pages/sales/teams/[id].tsx" /* webpackChunkName: "component---src-pages-sales-teams-[id]-tsx" */),
  "component---src-pages-sales-teams-tsx": () => import("./../../../src/pages/sales/teams.tsx" /* webpackChunkName: "component---src-pages-sales-teams-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-tournaments-events-tsx": () => import("./../../../src/pages/tournaments/events.tsx" /* webpackChunkName: "component---src-pages-tournaments-events-tsx" */),
  "component---src-pages-tournaments-matches-tsx": () => import("./../../../src/pages/tournaments/matches.tsx" /* webpackChunkName: "component---src-pages-tournaments-matches-tsx" */)
}

